import { apiUtils } from './apiUtils'

export const csrf = {
  get csrfToken() {
    return sessionStorage.getItem('csrfToken')
  },
  async fetchAndStoreCsrfToken() {
    return apiUtils
      .getCsrf()
      .then(async (response) => {
        const csrfToken = response.data.csrfToken
        sessionStorage.setItem('csrfToken', csrfToken)

        return csrfToken
      })
      .catch((e) => {
        console.error(e)

        return null
      })
  },
}