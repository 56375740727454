import { useContext } from 'react'
import { Accordion, AccordionGroup } from '@stillfront/stillfront-component-library'
import UserContext from '../../contexts/UserContext'
import { useModalContext } from '../../contexts/ModalContext'
import styles from './ManageAccounts.module.scss'
import { Game } from '../../contexts/types/game'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'
import { ConfirmUnlinkModalProps } from '../../components/ConfirmationModal/ConfirmUnlinkModal'

const ManageAccounts = () => {
  const { userData, loading } = useContext(UserContext)
  const { setModalContent, setModalPayload } = useModalContext<ConfirmUnlinkModalProps>()

  const unlinkGame = ({ game, playerId }: {game: Game, playerId: string}) => {
    setModalPayload({ playerId, titleId: game.titleId, title: game.title })
    setModalContent('confirmUnlink')
  }

  const gamesWithPlayers = userData?.games?.filter(
    (game) => game.playerIds?.length > 0
  ) ?? []

  const getImageSrc = (game: Game) =>
    game?.gameCardImages?.[0]
      ?? defaultImage

  const accordions = gamesWithPlayers?.map((game) => ({
    title: game.title,
    image: { src: getImageSrc(game), alt: game.title },
    children: game.playerIds.map(playerId =>
      <Accordion.Item
        text={playerId}
        buttonText="unlink"
        onClick={() => unlinkGame({ game, playerId })}
        key={playerId}
      />
    )
  }))

  return (
    <div className={`stillfront-content-container ${styles.container}`}>
      <AccordionGroup
        accordions={accordions}
        isLoading={loading}
        noItemsMessage="Here will be your games as soon as you link them."
      />
    </div>
  )
}

export default ManageAccounts
