import React from 'react'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'
import styles from './GameCard.module.scss'

export type GameCardProps = {
    image: string
    gameRedirectUrl: string
    titleId: string
    title: string
}

const GameCard = ({ image, gameRedirectUrl, titleId, title }: GameCardProps) => {
  const hasRedirectUrl = Boolean(gameRedirectUrl)

  const handleClick = () =>
    hasRedirectUrl
      ? window.location.href = gameRedirectUrl
      : null

  const cardClassName = `${styles.gameCard}${hasRedirectUrl ? ` ${styles.hasRedirect}` : ''}`

  return (
    <div className={cardClassName} onClick={handleClick}>
      <img src={image ?? defaultImage} className={styles.gameCardImage} alt={title} />
      <div className={styles.gameCardTitle}>{title}</div>
    </div>
  )
}

export default GameCard
