import successIcon from '../../assets/images/success.svg'
import warningIcon from '../../assets/images/warning-light.svg'
import { useContext, useEffect, useState } from 'react'
import { Button } from '@stillfront/stillfront-component-library'
import PropTypes from 'prop-types'
import BannerContext from '../../contexts/BannerContext'
import styles from './BaseBanner.module.scss'

const BaseBanner = ({ type, text, handleCallToAction, callToActionText, autoClose }) => {
  const [hideBanner, setHideBanner] = useState(false)
  const { setBannerContent } = useContext(BannerContext)

  useEffect(() => {
    autoClose && setTimeout(() => setHideBanner(true), 5000)
    autoClose && setTimeout(() => setBannerContent(null), 5500)
  }, [])
    
  const icon = type === 'success' ? successIcon : warningIcon

  const bannerClasses = `${styles.banner} ${styles[type + 'Banner']} ${hideBanner ? styles.hide : ''}`
  const iconClasses = `${styles.icon} ${styles[type + 'Icon']}`

  return (
    <div className={styles.bannerContainer}>
      <div className={bannerClasses}>
        <div className={styles.bannerContent}>
          <img
            src={icon}
            className={iconClasses}
            alt={type}
          />
          <p className={`body-1 ${styles.bannerContentText}`}>{text}</p>
        </div>
        {handleCallToAction && callToActionText && (
          <Button
            className={styles.bannerActionButton}
            variant="contained"
            size="small"
            onClick={handleCallToAction}
          >{callToActionText}</Button>
        )}
      </div>
    </div>
  )
}

BaseBanner.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleCallToAction: PropTypes.func,
  callToActionText: PropTypes.string,
  autoClose: PropTypes.bool,
}

export default BaseBanner
