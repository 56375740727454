import { GameDTO } from '../dtos/gameDTO'
import { Game, Games } from '../../contexts/types/game'

export const mapGames = (games: GameDTO[]): Games => {
  const recommendedGames: Game[] = []
  const featuredGames: Game[] = []

  games.forEach((game) => {
    if (game.recommendedGamesImages?.length > 0) {
      recommendedGames.push(mapGameDtoToGame(game))
    }
    if (game.featuredGamesImages?.length > 0) {
      featuredGames.push(mapGameDtoToGame(game))
    }
  })

  return { recommendedGames, featuredGames }
}

export const mapGameDtoToGame = (game: GameDTO): Game => ({
  titleId: game.titleId,
  playerIds: game.playerIds,
  title: game.title,
  recommendedGamesImages: game.recommendedGamesImages ?? [],
  featuredGamesImages: game.featuredGamesImages ?? [],
  gameCardImages: game.gameCardImages ?? [],
  gameRedirectUrl: game.gameLaunchUrl,
  subtitle: game.subtitle,
  platforms: game.platforms
})