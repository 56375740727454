import React, { useState, useEffect } from 'react'
import { Modal, Button } from '@stillfront/stillfront-component-library'
import { useCookies } from 'react-cookie'

const CookieConsent = () => {
  const [show, setShow] = useState(false)
  const [cookies, setCookie] = useCookies(['cookieConsent'])

  useEffect(() => {
    !cookies.cookieConsent && setShow(true)
  }, [cookies])

  const handleAccept = () => {
    setCookie('cookieConsent', true, { path: '/' })
    setShow(false)
  }

  return (
    <Modal position="bottom" isOpen={show} onClose={() => setShow(false)}>
      <Modal.Content>
        <p>
          We use cookies to enhance your user experience. By using our website, you agree to our use of cookies.{' '}
          <a href="/cookie-policy">Learn More.</a>
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button theme="light" variant="contained" onClick={handleAccept}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CookieConsent
