import { useContext } from 'react'
import ModalContext from '../../contexts/ModalContext'
import ConfirmUnlinkModal from '../../components/ConfirmationModal/ConfirmUnlinkModal'
import { isConfirmUnlinkModalProps } from './ModalTypeGuards'

export const ModalSection = () => {
  const activeModalComponent = getModalComponent()

  return <div>{activeModalComponent !== null && activeModalComponent}</div>
}

const getModalComponent = () => {
  const { modalContent, modalPayload } = useContext(ModalContext)

  switch(modalContent) {
    case 'confirmUnlink':
      return isConfirmUnlinkModalProps(modalPayload)
        ? (
          <ConfirmUnlinkModal
            titleId={modalPayload.titleId}
            playerId={modalPayload.playerId}
            title={modalPayload.title}
          />
        )
        : null
    default:
      return null
  }
}