import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { worker } from './mocks/handlers.js'
import { apiUtils } from './utils/apiUtils'
import { csrf } from './utils/csrf'

if (process.env.REACT_APP_MOCK === 'true') {
  await worker.start({ onUnhandledRequest: 'bypass' })
}

const getSession = async () => {
  const csrfToken = await csrf.fetchAndStoreCsrfToken()

  return !!csrfToken
}

getSession().then((status) => {
  if (status === true) {
    const root = ReactDOM.createRoot(document.getElementById('root'))
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  } else {
    console.log('not logged in. redirect.')
    apiUtils.doAuth()
  }
})
