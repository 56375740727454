import { Carousel, Hero } from '@stillfront/stillfront-component-library'
import { useContext, useEffect, useState } from 'react'
import styles from './FeaturedGames.module.scss'
import gameContext from '../../contexts/GameContext'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'

const FeaturedGames = () => {
  const { games, loading } = useContext(gameContext)
  const mediaQuery = window.matchMedia('(max-width: 576px)')
  const checkIsMobile = () => mediaQuery.matches
  const [isMobile, setIsMobile] = useState(checkIsMobile())

  useEffect(() => {
    const handleResize = () => setIsMobile(checkIsMobile())
    mediaQuery.addEventListener('change', handleResize)

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [])

  const images = games?.featuredGames?.map((game) => ({
    src: game.featuredGamesImages?.[0] ?? defaultImage,
    alt: game.title
  })) ?? []

  if (images.length === 0) images.push({ src: defaultImage, alt: defaultImage })

  return (
    <>
      {isMobile
        ? (
          <Hero
            className={styles.hero}
            imagePosition="center"
            images={images}
            autoplay={true}
            autoplayInterval={5000}
          />
        )
        : (
          <Carousel
            isLoading={loading}
            images={images}
            autoplay={true}
            autoplayInterval={5000}
            height="390"
            imagePosition="center"
          />
        )
      }
    </>
  )
}

export default FeaturedGames
