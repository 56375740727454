import { useContext } from 'react'
import profileLogo from '../../assets/images/profile-icon.svg'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'
import { Button } from '@stillfront/stillfront-component-library'
import styles from './AccountSettings.module.scss'
import { getCleanUserName } from '../../utils/userNameUtils'
import { ROUTES } from '../../appSections/ContentSection/ContentSection'

const AccountSettings = () => {
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()

  const handlePrivacyClick = () => navigate(ROUTES.PRIVACY)
  const handleManageAccounts = () => navigate(ROUTES.MANAGE_ACCOUNTS)
  const handleSignOut = () => window.location.href = `${process.env.REACT_APP_ACCOUNT_BACKEND_BASE_URL}/sign-out`

  const infoValues = [
    { key: 'Profile Name', value: userData && userData.userName ? userData.userName : 'placeholder' },
  ]

  return (
    <div className={`stillfront-content-container ${styles.container}`}>
      <img src={profileLogo} className={styles.profileLogo} alt="Profile logo" />
      <div>
        <h5 className={styles.infoCardHeading}>Your Information</h5>
        <div className={styles.infoCard}>
          {infoValues.map((infoValue, index) => {
            return (
              <div key={index}>
                <h5>{infoValue.key}:</h5>
                <p className="body-2">{infoValue.value}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <Button variant="contained" onClick={handlePrivacyClick}>Privacy & Communication</Button>
        <Button variant="outlined" onClick={handleManageAccounts}>Manage Game Accounts</Button>
        <Button variant="outlined" onClick={handleSignOut}>Sign Out</Button>
      </div>
    </div>
  )
}

export default AccountSettings
