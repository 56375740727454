import React, { createContext, useState } from 'react'

type BannerContent = 'deleteSuccess' | 'deleteError' | 'recoverSuccess' | 'recoverError' | 'unlinkSuccess' | 'unlinkError' | 'genericError' | null

const BannerContext = createContext<{
  bannerContent: BannerContent
  setBannerContent: (bannerContent: BannerContent) => void
    }>(null)

export const BannerProvider = ({ children }) => {
  const [bannerContent, setBannerContent] = useState<BannerContent>(null)

  return (
    <BannerContext.Provider value={{ bannerContent, setBannerContent }}>
      {children}
    </BannerContext.Provider>
  )
}

export default BannerContext