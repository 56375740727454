import { getRandomImageUrl } from './images'
import { GameDTO } from '../api/dtos/gameDTO'

export const unhappyGamesMock: GameDTO[] = [
  {
    titleId: 'unhappy1',
    playerIds: [], // no player ids
    title: 'Albion Online 2 - Orcs and fine dining 🐙',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    subtitle: null, // no subtitle
    platforms: {
      apple: true,
      google: true,
      windows: true
    }
  },
  {
    titleId: 'unhappy2',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8 Deluxe',
    recommendedGamesImages: [getRandomImageUrl()],
    featuredGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    subtitle: 'Embark on an epic quest filled with magic and mystery.',
    platforms: null // platforms is null
  },
  {
    titleId: 'unhappy3',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Albion Online 2 - Orcs and fine dining 🐙',
    featuredGamesImages: null,
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: null, // no gameLaunchUrl
    subtitle: 'Embark on an epic quest filled with magic and mystery.',
    platforms: {
      apple: null,
      google: null,
      windows: null
    }
  },
  {
    titleId: null, // titleId is null
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8 Deluxe',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: null,
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    subtitle: 'Embark on an epic quest filled with magic and mystery.',
    platforms: {
      apple: true,
      google: false,
      windows: false
    }
  }
]