import React, { createContext, ReactNode, useState, useContext } from 'react'

type ModalContent = 'confirmUnlink' | null

export type ModalContextArgs<T = unknown> = {
  modalContent: ModalContent
  closeModal: () => void
  setModalContent: (modalContent: ModalContent) => void
  modalPayload: T
  setModalPayload: (payload: T) => void
}

const ModalContext = createContext<ModalContextArgs<any>>(null)
export const useModalContext = <T,>() => useContext(ModalContext) as ModalContextArgs<T> | null

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ModalContent>(null)
  const [modalPayload, setModalPayload] = useState<any>(null)
  const closeModal = () => {
    setModalContent(null)
    setModalPayload(null)
  }

  return (
    <ModalContext.Provider value={{ modalContent, setModalContent, modalPayload, setModalPayload, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext