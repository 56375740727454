import React, { createContext, useEffect, useState, useCallback } from 'react'
import { apiUtils } from '../utils/apiUtils'
import {
  createRemovePlayerIdFromGames,
  createSetExpired,
  createUpdateAcceptedMarketing,
  createUpdateAcceptedNotifications,
} from './UserMutations'
import { User } from './types/user'
import { AxiosError, AxiosResponse } from 'axios'
import { UserResponse } from '../api/responses/userResponse'
import { mapUserResponseToUser } from '../api/mappers/userMapper'

const UserContext = createContext<{
  userData: User
  loading: boolean
  error: Error
  removePlayerIdFromGames: ({ playerId, titleId }) => void
  updateAcceptedMarketing: () => void
  updateAcceptedNotifications: () => void
  setExpired: () => void
    }>(null)

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState<User>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>(null)

  const updateAcceptedMarketing = useCallback(createUpdateAcceptedMarketing(setUserData), [])
  const updateAcceptedNotifications = useCallback(createUpdateAcceptedNotifications(setUserData), [])
  const removePlayerIdFromGames = useCallback(createRemovePlayerIdFromGames(setUserData), [])
  const setExpired = useCallback(createSetExpired(setUserData), [])

  useEffect(() => {
    const fetchUserData = () => {
      apiUtils
        .getUser()
        .then((response: AxiosResponse<UserResponse>) =>
          setUserData(mapUserResponseToUser(response.data)))
        .catch((error: AxiosError) => setError(error))
        .finally(() => setLoading(false))
    }

    fetchUserData()
  }, [])

  const value = {
    userData,
    loading,
    error,
    removePlayerIdFromGames,
    updateAcceptedMarketing,
    updateAcceptedNotifications,
    setExpired
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext