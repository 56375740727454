import { BrowserRouter as Router } from 'react-router-dom'
import Header from './appSections/Header/Header'
import CookieConsent from './components/CookieConsent/CookieConsent'
import { BannerSection } from './appSections/BannerSection/BannerSection'
import { ContentSection } from './appSections/ContentSection/ContentSection'
import { ModalSection } from './appSections/ModalSection/ModalSection'
import { Providers } from './contexts/Providers'
import './App.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@stillfront/stillfront-component-library/dist/main.scss'

function App() {
  return (
    <Providers>
      <Router>
        <div className="app dark">
          <Header />
          <BannerSection />
          <ContentSection>
            <ModalSection />
          </ContentSection>
          <CookieConsent />
        </div>
      </Router>
    </Providers>
  )
}

export default App
