import { unhappyGamesMock } from './unhappyGames'
import { GameDTO } from '../api/dtos/gameDTO'
import { getRandomImageUrl } from './images'

export const gamesMock: GameDTO[] = [
  // -----------------------
  // happy games mocks
  // -----------------------
  {
    titleId: 'happy1',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Albion Online 2',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    subtitle: 'Embark on an epic quest filled with magic and mystery.',
    platforms: {
      apple: true,
      google: true,
      windows: true
    }
  },
  {
    titleId: 'happy2',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Water Parks 🐳',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.nintendo.de/de/mario-kart?srsltid=AfmBOorys2AGn8KuATVOx9vPYu0FVkYHpjWprGiVD88WZpGk-RXyB1fP',
    subtitle: 'Join the ultimate battle for supremacy in this strategic adventure',
    platforms: {
      apple: true,
      google: false,
      windows: true
    }
  },
  {
    titleId: 'happy3',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.nintendo.de/de/mario-kart?srsltid=AfmBOorys2AGn8KuATVOx9vPYu0FVkYHpjWprGiVD88WZpGk-RXyB1fP',
    subtitle: 'Explore vast worlds, uncover secrets, and become a legend yourself.',
    platforms: {
      apple: true,
      google: true,
      windows: true
    }
  },
  // -----------------------
  // unhappy games mocks
  // -----------------------
  ...unhappyGamesMock
]