import React, { useContext } from 'react'
import styles from './RecommendedGames.module.scss'
import { SlideGroup } from '@stillfront/stillfront-component-library'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'
import gameContext from '../../contexts/GameContext'
import { Game } from '../../contexts/types/game'
import { SlideProps } from '@stillfront/stillfront-component-library/dist/components/slide-group/slide'

const RecommendedGames = () => {
  const { games, loading } = useContext(gameContext)

  const mapRecommendedGameToSlide = (game: Game): SlideProps => ({
    image: {
      src: game.recommendedGamesImages?.[0] ?? defaultImage,
      alt: game.title
    },
    title: game.title,
    subtitle: game.subtitle,
    badge: {
      apple: game.platforms?.apple ?? false,
      google: game.platforms?.google ?? false,
      windows: game.platforms?.windows ?? false
    },
    onClick: () => {
      if (game?.gameRedirectUrl) window.location.href = game.gameRedirectUrl
    }
  })

  const slides = games?.recommendedGames.map(mapRecommendedGameToSlide) ?? null

  // @todo showing this on load and then hide it when length === 0 is not a good UX
  return (
    <div className={styles.allGamesContainer}>
      {slides?.length === 0
        ? null
        : (
          <>
            <h3>Recommended Games</h3>
            {
              loading
                ? <p className={`body-2 ${styles.loadingText}`}>Loading Games...</p>
                : <SlideGroup slides={slides} />
            }
          </>
        )
      }
    </div>
  )
}

export default RecommendedGames
