import { Button, Checkbox } from '@stillfront/stillfront-component-library'
import '@stillfront/stillfront-component-library/dist/main.scss'
import { Link, useNavigate } from 'react-router-dom'
import styles from './PrivacyAndSettings.module.scss'
import { apiUtils } from '../../utils/apiUtils'
import { useContext } from 'react'
import UserContext from '../../contexts/UserContext'
import BannerContext from '../../contexts/BannerContext'
import { ROUTES } from '../../appSections/ContentSection/ContentSection'

const PrivacyAndSettings = () => {
  const { userData, updateAcceptedMarketing, updateAcceptedNotifications, loading } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)
  const navigate = useNavigate()

  const handleDelete = () => {
    navigate(ROUTES.DELETE_ACCOUNT)
  }

  const handleNotificationsToggle = (checked) => {
    const previousValue = userData.acceptedNotifications
    updateAcceptedNotifications(checked)
    apiUtils.updateUser(({ ...userData, acceptedNotifications: checked }))
      .catch((error) => {
        console.error('Failed to update terms: ', error)
        setBannerContent('genericError')
        updateAcceptedNotifications(previousValue)
      })
  }

  const handleMarketingToggle = (checked) => {
    const previousValue = userData.acceptedMarketing
    updateAcceptedMarketing(checked)
    apiUtils.updateUser({ ...userData, acceptedMarketing: checked })
      .catch((error) => {
        console.error('Failed to update marketing: ', error)
        updateAcceptedMarketing(previousValue)
        setBannerContent('genericError')
      })
  }

  return (
    <div className={`stillfront-content-container ${styles.container}`}>
      <Checkbox
        checked={userData ? userData.acceptedMarketing : false}
        onChange={handleMarketingToggle}
        label="I want to receive news, offers, and gifts via email"
        isLoading={loading}
      />
      <Checkbox
        checked={userData ? userData.acceptedNotifications : false}
        onChange={handleNotificationsToggle}
        label="I want to receive tailored ads and recommendations"
        isLoading={loading}
      />
      <div>
        By using our service, you agree to our Privacy and Terms and Conditions. For comprehensive details,
        follow&nbsp;
        <Link to="/terms-and-conditions" className={styles.link}>this link.</Link>
      </div>
      <Button variant="outlined" onClick={handleDelete}>Delete Account</Button>
    </div>
  )
}

export default PrivacyAndSettings
