import React from 'react'
import GameCard, { GameCardProps } from './GameCard'
import styles from './GameCardGroup.module.scss'

export type GameCardGroupProps = {
    gameCards: GameCardProps[]
    isLoading: boolean
    noItemsMessage?: string
}

const GameCardGroup = ({ gameCards, isLoading, noItemsMessage }: GameCardGroupProps) =>
  isLoading
    ? <p className={`${styles.gameCardGroupText} body-2`}>Loading games...</p>
    : (
      <div className={styles.gameCardGroup}>
        {gameCards && gameCards.length > 0
          ? ( gameCards.map(game =>
            <GameCard
              title={game.title}
              titleId={game.titleId}
              image={game.image}
              gameRedirectUrl={game.gameRedirectUrl}
              key={game.titleId}
            />
          ))
          : <p className={`${styles.gameCardGroupText} body-2`}>{noItemsMessage}</p>
        }
      </div>
    )

export default GameCardGroup
