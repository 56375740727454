import { http, HttpResponse, delay } from 'msw'
import { setupWorker } from 'msw/browser'
import { userMock } from './user'
import { gamesMock } from './games'

const DELAY = 3000

const baseUrl = process.env.REACT_APP_ACCOUNT_BACKEND_BASE_URL

export const handlers = [
  // Update User
  http.put(`${baseUrl}/v1/user`, async () => {
    return HttpResponse.json()
  }),

  // Recover User
  http.put(`${baseUrl}/v1/user/recover`, async () => {
    return HttpResponse.json()
  }),

  // Get User
  http.get(`${baseUrl}/v1/user`, async () => {
    // await delay(DELAY)
    return HttpResponse.json(userMock)
  }),

  // Get Games
  http.get(`${baseUrl}/v1/games`, async () => {
    await delay(DELAY)
    return HttpResponse.json({
      games: gamesMock
    })
  }),

  // Delete User
  http.delete(`${baseUrl}/v1/user`, async () => {
    return HttpResponse.json()
  }),

  // Unlink Game
  http.delete(`${baseUrl}/v1/player`, async () => {
    return HttpResponse.json()
  }),

  // Start
  http.get(`${baseUrl}/start`, async () => {
    return HttpResponse.json({ csrfToken: '123' })
  })
]

export const worker = setupWorker(...handlers)