const imageOptions = [
  'https://media.wired.com/photos/5f87340d114b38fa1f8339f9/master/w_1600%2Cc_limit/Ideas_Surprised_Pikachu_HD.jpg',
  'https://static0.gamerantimages.com/wordpress/wp-content/uploads/2023/03/pikachu-pokemon-journeys.jpg',
  'https://pbs.twimg.com/media/DCni0UgXcAEocwG?format=jpg&name=4096x4096',
  'https://assets-config.iddev.stillfront.com/163_recommendedgame_ff117bbc-3ebe-4535-97aa-cfd29323e137.png',
  null
]

export const getRandomImageUrl = (): string => {
  const randomIndex = Math.floor(Math.random() * imageOptions.length)
  return imageOptions[randomIndex]
}