import React from 'react'
import { BannerProvider } from './BannerContext'
import { ModalProvider } from './ModalContext'
import { UserProvider } from './UserContext'
import { GameProvider } from './GameContext'
import PropTypes from 'prop-types'

export const Providers = ({ children }) => {
  return (
    <UserProvider>
      <GameProvider>
        <BannerProvider>
          <ModalProvider>
            {children}
          </ModalProvider>
        </BannerProvider>
      </GameProvider>
    </UserProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
}