import React, { useContext, useEffect, useState } from 'react'
import styles from './MyGames.module.scss'
import userContext from '../../contexts/UserContext'
import GameCardGroup from '../GameCard/GameCardGroup'
import { Game } from '../../contexts/types/game'
import { GameCardProps } from '../GameCard/GameCard'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'

const mapUserDataToGameCard = (userData: {games: Game[]}): GameCardProps[] =>
  userData?.games.map((game) => ({
    image: game.gameCardImages?.[0] ?? defaultImage,
    gameRedirectUrl: game.gameRedirectUrl,
    titleId: game.titleId,
    title: game.title,
  }))

const MyGames = () => {
  const { userData, loading }: any = useContext(userContext)
  const [games, setGames] = useState([])

  useEffect(() => {
    setGames(
      userData?.games
        ? mapUserDataToGameCard(userData)
        : []
    )
  }, [userData?.games, loading])

  return (
    <div className={styles.myGamesContainer}>
      <h3>My Games</h3>
      <GameCardGroup
        gameCards={games}
        isLoading={loading}
        noItemsMessage="Here will be your games as soon as you link them."
      />
    </div>
  )
}

export default MyGames
